.containerMain {
    height: 100vh;
    width: 100%;
    display: flex;
    z-index: 5;
}

.wrapperTitulo {
    width: 50%;
}

.wrapperContador {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-direction: column;
}

.wrapperContador button {
    margin-top: 20px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    padding: 7px 15px;
    border-radius: 10px;
    cursor: pointer;
    height: 50px;
    width: 150px;
}

.wrapperContador button:hover {
    background: rgba(105, 28, 140, 0.400);
}

.containerCuenta {
    display: flex;
    gap: 20px;

}

.cuadrado {
    background-color: rgba(255, 255, 255, 0.452);
    height: 110px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cuadrado .numero {
    font-size: 40px;
    color: white;
    font-weight: 400;
}

.cuadrado .formato {
    color: white;
    font-size: 15px;
}

.wrapperTitulo {
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);

}

.wrapperTitulo p {
    color: white;
    font-size: 70px;
    font-weight: bold;
    line-height: 70px;
}

@media only screen and (max-width: 600px) {
    .containerMain {
        height: calc(100vh);
        width: 100%;
        display: flex;
        z-index: 5;
        flex-direction: column;
    }

    .wrapperTitulo {
        width: 100%;
        padding-top: 40px;
    }

    .wrapperContador {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        padding-top: 40px;
    }

    .wrapperContador button {
        margin-top: 20px;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        padding: 7px 15px;
        border-radius: 10px;
        cursor: pointer;
    }

    .containerCuenta {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .cuadrado {
        background-color: rgba(255, 255, 255, 0.452);
        height: 110px;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .cuadrado .numero {
        font-size: 40px;
        color: white;
        font-weight: 400;
    }

    .cuadrado .formato {
        color: white;
        font-size: 15px;
    }

    .wrapperTitulo {
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.075);
        border-right: 2px solid rgba(0, 0, 0, 0.068);
    }

    .wrapperTitulo p {
        color: white;
        font-size: 45px;
        font-weight: bold;
        line-height: 45px;

    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .containerMain {
        height: 700px;
        width: 100%;
        display: flex;
        z-index: 5;
        flex-direction: column;
        padding-top: 20px;
        justify-content: center;

    }

    .wrapperTitulo {
        width: 100%;

    }

    .wrapperContador {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        padding-top: 40px;
    }

    .wrapperContador button {
        margin-top: 20px;
        color: white;
        background-color: transparent;
        border: 1px solid white;
        padding: 7px 15px;
        border-radius: 10px;
        cursor: pointer;
    }

    .containerCuenta {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .cuadrado {
        background-color: rgba(255, 255, 255, 0.452);
        height: 110px;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .cuadrado .numero {
        font-size: 40px;
        color: white;
        font-weight: 400;
    }

    .cuadrado .formato {
        color: white;
        font-size: 15px;
    }

    .wrapperTitulo {
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.075);
        border-right: 2px solid rgba(0, 0, 0, 0.068);
    }

    .wrapperTitulo p {
        color: white;
        font-size: 45px;
        font-weight: bold;

    }
}

.wrapperContador span {
    color: white;
    font-size: 40px;
    width: 100%;
    text-align: center;
}