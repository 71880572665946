.sistemaContainer {
    height: 600px;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sistemaItem {
    width: 70%;
    height: 70%;
    background-color: rgba(255, 255, 255, 0.333);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.429);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sistemaContenido h1 {
    text-shadow: 1px 1px 3px black;
}


.sistemaItem p {
    width: 80%;
    text-align: center;
    margin: 0;
    text-shadow: 1px 1px 1px black;
    font-size: 17px;


}

.sistemaContenido {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    color: white;
}

.sistemaBotones {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.sistemaBotones button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.701);
    border-radius: 10px;
    color: white;
    text-shadow: 1px 1px 1px black;
    height: 50px;
    width: 250px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {


    .sistemaContainer {
        height: auto;
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        padding-top: 0px;
    }

    .sistemaItem {
        width: 80%;
        height: 90%;
        background-color: rgba(255, 255, 255, 0.333);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.429);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sistemaContenido h1 {
        text-shadow: 1px 1px 3px black;
    }


    .sistemaItem p {
        width: 90%;
        text-align: center;
        margin: 0;
        text-shadow: 1px 1px 1px black;
        font-size: 14px;


    }

    .sistemaContenido {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: white;
    }

    .sistemaBotones {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    .sistemaBotones button {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.701);
        border-radius: 10px;
        color: white;
        text-shadow: 1px 1px 1px black;
        height: 50px;
        width: 250px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
    }
}

.sistemaBotones button:hover {
    background: rgba(105, 28, 140, 0.400);
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .sistemaContainer {
        height: auto;
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50PX;
    }

    .sistemaItem {
        width: 80%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.333);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.429);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sistemaContenido h1 {
        text-shadow: 1px 1px 3px black;
    }


    .sistemaItem p {
        width: 90%;
        text-align: center;
        margin: 0;
        text-shadow: 1px 1px 1px black;
        font-size: 14px;
    }

    .sistemaContenido {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: white;
    }

    .sistemaBotones {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    .sistemaBotones button {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.701);
        border-radius: 10px;
        color: white;
        text-shadow: 1px 1px 1px black;
        height: 50px;
        width: 250px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
    }
}

.sistemaBotones a {
    color: white;
    text-decoration: none;
}