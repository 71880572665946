.atencionPrincipal {
    height: 600px;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;
    position: relative;

    flex-direction: column;
}

.atencionContainer {
    background-color: rgba(255, 255, 255, 0.212);
    width: 70%;
    height: 50%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.324);
    backdrop-filter: blur(20px);
    color: white;
    text-align: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.atencionTitulo p {
    margin-bottom: 50px;
}

.atencionItems {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 50px;
    color: white;
}

.atencionItems svg {
    font-size: 3rem;
}

.atencionItems p {
    margin: 0;
    font-weight: 00;
    font-size: 1.2rem;

}

footer {
    text-align: center;
    color: white;
    height: 100px;
    display: flex;
    align-items: flex-end;
    position: relative;

}

@media only screen and (max-width: 600px) {
    .atencionPrincipal {
        height: auto;
        backdrop-filter: blur(5px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .atencionContainer {
        background-color: rgba(255, 255, 255, 0.212);
        width: 90%;
        height: 50%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid rgba(255, 255, 255, 0.324);
        backdrop-filter: blur(20px);
        color: white;
        text-align: center;
        padding-bottom: 50px;
        padding-top: 50px;


    }

    .atencionTitulo {
        padding: 10px;
        font-size: 14px;
    }

    .atencionTitulo p {
        margin-bottom: 50px;
    }

    .atencionItems {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 15px;
        color: white;
    }

    .atencionItems svg {
        font-size: 3rem;
    }

    .atencionItems p {
        margin: 0;
        font-weight: 200;
        font-size: 1rem;
    }

    footer {
        text-align: center;
        color: white;
    }

    .imagenPaula {
        position: absolute;
        left: -400px;
        top: 80px;
    }

    .imagenPaula img {
        position: absolute;
        height: 400px !important;
        transition: 2s;
    }

    .imagenPaula p {
        position: absolute;
        right: 80px;
        bottom: -380px !important;
        ;
        color: white;
        font-size: 25px;
        padding: 5px 20px;
        border-radius: 10px;
        z-index: -1;
        transition: 2s;

    }

    .activePosition {
        transform: translateX(370px) !important;
        margin-top: 20px;
        transition: 2s;
    }


    .activePositionp {
        transform: translateX(650px) !important;
        margin-top: 20px;
        transition: 2s;
    }



}

.imagenPaula {
    position: absolute;
    left: -400px;
    top: 80px;
}

.imagenPaula img {
    position: absolute;
    height: 500px;
    transition: 2s;
}

.imagenPaula p {
    position: absolute;
    bottom: -495px;
    ;
    color: white;
    font-size: 25px;
    padding: 5px 20px;
    border-radius: 10px;
    z-index: -1;
    transition: 2s;

}

.activePosition {
    transform: translateX(40vw);
    margin-top: 20px;
    transition: 2s;
}


.activePositionp {
    transform: translateX(57vw);
    margin-top: 20px;
    transition: 2s;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .imagenPaula {
        position: absolute;
        left: -450px;
        top: 90px;
    }

    .imagenPaula img {
        position: absolute;
        height: 580px;
        transition: 2s;
    }

    .imagenPaula p {
        position: absolute;
        right: 80px;
        bottom: -475px;
        ;
        color: white;
        font-size: 25px;
        padding: 5px 20px;
        border-radius: 10px;
        z-index: -1;
        transition: 2s;

    }

    .activePosition {
        transform: translateX(510px);
        margin-top: 20px;
        transition: 2s;
    }


    .activePositionp {
        transform: translateX(830px);
        margin-top: 0px;
        transition: 2s;
    }
}