.navBar {
    height: 80px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 900;
    width: 100%;
    max-width: 2000px;
}

.imagen {

    height: 80px;
    margin-left: 50px;
    display: flex;
}

.imagen img {
    height: 100%;
    margin-top: 5px;
}


.items {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.items a {
    color: white;
    text-shadow: 1px 1px 1px black;
    cursor: pointer;
}

.botonheader {
    display: none;
}

/*Barra principal de footer*/
.barra {
    background-color: rgba(188, 10, 215, 0.148);
    box-shadow: 1px 1px 5px rgb(255, 255, 255)
}

@media only screen and (max-width: 600px) {
    .navBar {
        height: 70px;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 900;
        width: 100%;
        max-width: 2000px;
    }

    .imagen {

        height: 50px;
        margin-left: 50px;
    }




    .items {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .items a {
        color: white;
        text-shadow: 1px 1px 1px black;
        cursor: pointer;
    }

    .barra {
        background-color: rgba(255, 255, 255, 0.452);
    }

    .items {
        display: none;
    }

    .navBar {
        height: 70px;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 900;
        width: 100%;
        max-width: 2000px;
        justify-content: space-between;
    }


    .botonheader {
        margin-right: 20px;
        font-size: 40px;
        color: #522573;
        display: block !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .items {
        display: none;
    }

    .navBar {
        height: 70px;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 900;
        width: 100%;
        max-width: 2000px;
        justify-content: space-between;
    }

    .botonheader {
        margin-right: 20px;
        font-size: 40px;
        color: #522573;
        display: block !important;

    }
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.offcanvas-backdrop.fade {
    opacity: 0;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity .15s linear;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none;
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
    visibility: visible;
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: var(--bs-border-width);
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5;
}

.offcanvas.offcanvas-start {
    background-color: rgba(255, 255, 255, 0.500);
    height: 320px;
    width: 200px;
    margin: auto;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    box-shadow: 1px 1px 3px rgb(255, 255, 255);
}

.canvasContainer {
    display: flex;
    flex-direction: column;
    color: white;
    text-shadow: 1px 1px black;
    font-size: 20px;
    gap: 5px;
    margin-left: 10px;
    cursor: pointer;
}