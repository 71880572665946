.calendarContainer {
    height: 600px;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(10px);
    align-items: center;
}

.calendarCalendario {
    height: 100%;
    width: 65%;
    background-color: rgba(255, 255, 255, 0.253);
    padding: 0px 60px 0px 60px;
    position: relative;
}

.calendarCalendarios {
    height: 75%;
    width: 100%;
}

.fc {
    height: 100%;
    color: white;
}

.fc-toolbar-chunk {
    width: 100%;
    text-align: center;
}

.fc .fc-toolbar {
    display: block !important;
    margin-bottom: 0 !important;
    height: 50px !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid rgba(0, 0, 0, 0.219) !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: 2px solid rgba(0, 0, 0, 0.219) !important;
}

.fc table {
    border: 1px solid rgba(0, 0, 0, 0.219) !important;
}

.fc-event {
    color: white !important;
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: 500 !important;
}


.calendarBotones {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 40px;

}

.calendarBotones button {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.616);
    width: 170px;
    height: 35px;
    cursor: pointer;
    color: white;
    text-shadow: 1px 1px 1px black;
    position: relative;
    z-index: 1000;
}

.fc-toolbar-chunk button {
    display: none !important;
}

.tituloCartas {
    display: flex;
    justify-content: center;
    color: white;
}

.tituloAgenda h1 {
    color: white;
    font-size: 60px;
}

.fc-daygrid-event {
    border-radius: 3px;
    font-size: var(--fc-small-font-size);
    position: relative;
    white-space: pre-wrap !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.300);
    text-shadow: 1px 1px 1px black;
    position: relative;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end:hover,
.fc-direction-rtl .fc-daygrid-event.fc-event-start:hover {
    background-color: rgba(255, 255, 255, 0.550);
    text-shadow: 1px 1px 1px black;

}

.fc-daygrid-event-dot {
    display: none;
}

.fc-event-time {
    display: none;
}

@media only screen and (max-width: 600px) {
    .calendarContainer {
        height: 500px;
        display: flex;
        justify-content: center;
        backdrop-filter: blur(10px);
        align-items: center;
        padding: 10px;
    }

    .calendarCalendario {
        height: 550px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.253);
        padding: 10px;
        font-size: 11px;
    }

    .calendarCalendarios {
        height: 350px;
        width: 100%;
    }


    .tituloAgenda {
        position: absolute;
        top: -90px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .fc {
        height: 100%;
        color: white;
    }

    .fc-toolbar-chunk {
        width: 100%;
        text-align: center;
    }

    .fc .fc-toolbar {
        display: block !important;
        margin-bottom: 0 !important;
        height: 50px !important;
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
        border: 1px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: 2px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc table {
        border: 1px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc-event {
        color: white !important;
    }

    .fc-daygrid-dot-event .fc-event-title {
        font-weight: 500 !important;
        width: 100%;
    }


    .calendarBotones {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        height: 40px;
        flex-wrap: wrap;
        height: 100px;
    }

    .calendarBotones button {
        background-color: transparent;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.616);
        width: 120px;
        height: 35px;
        cursor: pointer;
        color: white;
        text-shadow: 1px 1px 1px black;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-end,
    .fc-direction-rtl .fc-daygrid-event.fc-event-start {
        margin-right: 2px;
        display: flex;
        flex-direction: column;
        padding: 3px;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
        background-color: rgba(255, 255, 255, 0.300);
        text-shadow: 1px 1px 1px black;
        position: relative;
    }


    .fc-toolbar-chunk button {
        display: none !important;
    }
}

.fc-toolbar-title::first-letter {
    text-transform: capitalize;
}

.fc-scrollgrid-sync-table tbody tr:nth-child(1),
.fc-scrollgrid-sync-table tbody tr:nth-child(2),
.fc-scrollgrid-sync-table tbody tr:nth-child(4),
.fc-scrollgrid-sync-table tbody tr:nth-child(5) {
    display: none;
}

.seleccionado {
    background-color: rgba(105, 28, 140, 0.400) !important;
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
    .calendarContainer {
        height: 500px;
        display: flex;
        justify-content: center;
        backdrop-filter: blur(10px);
        align-items: center;
        padding: 10px;
    }

    .calendarCalendario {
        height: 550px;
        width: 90% !important;
        background-color: rgba(255, 255, 255, 0.253);
        padding: 10px;
        font-size: 11px;
        margin-top: 100px;
        padding-top: 50px;
    }



    .calendarCalendarios {
        height: 400px;
        width: 100%;
    }


    .tituloAgenda {
        position: absolute;
        top: -90px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .fc {
        height: 100%;
        color: white;
    }

    .fc-toolbar-chunk {
        width: 100%;
        text-align: center;
    }

    .fc .fc-toolbar {
        display: block !important;
        margin-bottom: 0 !important;
        height: 50px !important;
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
        border: 1px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: 2px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc table {
        border: 1px solid rgba(0, 0, 0, 0.219) !important;
    }

    .fc-event {
        color: white !important;
    }

    .fc-daygrid-dot-event .fc-event-title {
        font-weight: 500 !important;
        width: 100%;
    }


    .calendarBotones {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        height: 40px;
        flex-wrap: wrap;
        height: auto;
    }

    .calendarBotones button {
        background-color: transparent;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.616);
        width: 120px;
        height: 35px;
        cursor: pointer;
        color: white;
        text-shadow: 1px 1px 1px black;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-end,
    .fc-direction-rtl .fc-daygrid-event.fc-event-start {
        margin-right: 2px;
        display: flex;
        flex-direction: column;
        padding: 3px;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
        background-color: rgba(255, 255, 255, 0.300);
        text-shadow: 1px 1px 1px black;
        position: relative;
    }


    .fc-toolbar-chunk button {
        display: none !important;
    }
}