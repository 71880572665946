.containerCartas {
    height: 400px;
    width: 100%;
    display: flex;
    backdrop-filter: blur(10px);
    padding-bottom: 50px;
}

.cartasDesc {
    width: 30%;
}


.cartasCard {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.cartasCard div {
    height: 300px;
    width: 200px;
    text-align: center;
    padding: -20px;
    background-color: rgba(255, 255, 255, 0.404);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.253);
    transition: .7s;
    cursor: pointer;

}

.cartasCard div:hover {
    transition: .7s;
    transform: scale(1.1);

}
/*Texto del titulo de tarjetas*/
.cartasCard h3 {
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgb(83, 14, 112);

}

.cartasCard button {
    margin-bottom: 50px;
    background-color: transparent;
    width: 80%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    height: 30px;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;

}

.cartasDesc {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    ;
}

.cartasDesc div {
    width: 90%;
}

.cartasDesc p {
    font-weight: 600;
    text-shadow: 1px 1px black;
    text-align: start;
    width: 100%;
    font-size: 17px;
}


@media only screen and (max-width: 600px) {
    .containerCartas {
        height: auto;
        width: 100%;
        display: flex;
        backdrop-filter: blur(10px);
        flex-direction: column-reverse;
    }

    .cartasDesc {
        width: 100%;
    }


    .cartasCard {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .cartasCard div {
        height: 200px;
        width: 150px;
        text-align: center;
        padding: -20px;
        background-color: rgba(255, 255, 255, 0.404);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: white;
        border: 2px solid rgba(255, 255, 255, 0.253);
        transition: .7s;

    }

    .cartasCard div:hover {
        transition: .7s;
        transform: scale(1.1);

    }

    .cartasCard h3 {
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: 600;

    }

    .cartasCard button {
        margin-bottom: 50px;
        background-color: transparent;
        width: 80%;
        border: 1px solid white;
        border-radius: 10px;
        height: 30px;

        color: white;
    }

    .cartasDesc {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        flex-direction: column;
        ;
    }

    .cartasDesc div {
        width: 90%;
    }

    .cartasDesc p {
        font-weight: 600;
        text-shadow: 1px 1px black;
        text-align: start;
        width: 100%;
        font-size: 17px;
    }

}

.tallerescorrespondientes li {
    margin-top: 10px;


}

h1.tituloCartas {
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    padding: 0;
    margin: 0;
    font-size: 50px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .containerCartas {
        height: auto;
        width: 100%;
        display: flex;
        backdrop-filter: blur(10px);
        flex-direction: column-reverse;
        margin-bottom: 50px;
    }

    .cartasDesc {
        width: 100%;
    }


    .cartasCard {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .cartasCard div {
        height: 200px;
        width: 150px;
        text-align: center;
        padding: -20px;
        background-color: rgba(255, 255, 255, 0.404);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: white;
        border: 2px solid rgba(255, 255, 255, 0.253);
        transition: .7s;

    }

    .cartasCard div:hover {
        transition: .7s;
        transform: scale(1.1);

    }

    .cartasCard h3 {
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: 600;

    }

    .cartasCard button {
        margin-bottom: 50px;
        background-color: transparent;
        width: 80%;
        border: 1px solid white;
        border-radius: 10px;
        height: 30px;

        color: white;
    }

    .cartasDesc {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        flex-direction: column;
        ;
    }

    .cartasDesc div {
        width: 90%;
    }

    .cartasDesc p {
        font-weight: 600;
        text-shadow: 1px 1px black;
        text-align: start;
        width: 100%;
        font-size: 17px;
    }
}