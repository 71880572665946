.sliderContainer {
    height: 500px;
    width: 100%;
    display: flex;
    flex: none;
    padding-top: 100px;
}

.slider {
    position: relative;
    z-index: 10;
    margin-top: 50px;

}


.containerFoto {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 94%;

}

.containerDescripcion {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.containerFoto img {
    height: 100%;
    /*     opacity: 0;
 */
    transition: 0.7s ease-in-out all;
    opacity: 0;
    margin-left: 30px;
    filter: drop-shadow(4px 1px 5px rgba(0, 0, 0, 0.507));

}

.containerFoto img.active {
    animation: bounce2 1s ease;
    opacity: 1;
    transition: .7s;
}

@keyframes bounce2 {

    0% {
        transform: translateX(-400px);
    }

    100% {
        transform: translateX(0px);
    }
}

.containerDescripcion button {
    background-color: transparent;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    border: 1px solid white;
    color: white;
    margin-left: 20px;
    margin-bottom: 20px;
}

.containerDescripcion button:hover {
    background: rgba(105, 28, 140, 0.400);
}

.containerDescripcion {
    color: white;
    padding: 20px;
    text-align: justify;
}

.slider {
    display: flex;
    max-width: 100%;
    overflow: hidden;
}

.containerDescripcion p,
.containerDescripcion h1 {

    opacity: 0;
}

.containerDescripcion p {
    width: 70%;
    height: 200px;
    overflow-y: hidden;

}

.containerDescripcion p.activo,
.containerDescripcion h1.activo {
    animation: myAnim 1s ease 0s 1 normal forwards;
    opacity: 1;

}

@keyframes myAnim {
    0% {
        transform: translateX(600px);
    }

    100% {
        transform: translateX(0px);
    }
}

.containerDescripcion h1 {
    margin: 0;
    width: 70%;

}

.containerDescripcion p {
    margin-bottom: 20px;
    height: 300px;
}

.Borde {
    width: 95%;
    height: 540px;
    position: absolute;
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.400);

}

.Borde2 {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);

}

@media only screen and (max-width: 600px) {
    .sliderContainer {
        height: calc(100vh - 100px);
        width: 100%;
        display: flex;
        flex: none;
        padding-top: 80px !important;
        flex-direction: column;

    }

    .slider {
        position: relative;
        z-index: 10;
        ;

    }


    .containerDescripcion p {
        height: 35vh;
    }


    .containerFoto {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 35%;
        margin-bottom: 10px;
    }

    .containerFoto>div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

    }

    .containerDescripcion {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-right: 0px !important;
        padding-top: 0px !important;
    }

    .containerFoto img {
        height: 100%;
        /*     opacity: 0;
     */
        transition: 0.7s ease-in-out all;
        opacity: 0;
        margin-left: 0px;
        margin-top: 5px;
    }

    .containerFoto img.active {
        animation: bounce2 1s ease;
        opacity: 1;
        transition: .7s;
    }

    @keyframes bounce2 {

        0% {
            transform: translateX(-400px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    .containerDescripcion button {
        background-color: transparent;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        border: 1px solid white;
        color: white;
        padding: 0 !important;

    }

    .containerDescripcion button:hover {
        background: rgb(105, 28, 140);
        background: radial-gradient(circle, rgba(105, 28, 140, 0.7933298319327731) 0%, rgba(148, 233, 233, 0) 100%);

    }

    .containerDescripcion {
        color: white;
        text-align: justify;
    }

    .slider {
        display: flex;
        max-width: 100%;
        overflow: hidden;
    }

    .containerDescripcion p,
    .containerDescripcion h1 {
        opacity: 0;
    }

    .containerDescripcion p {
        width: 90%;
        font-size: 12px;
    }

    .containerDescripcion p.activo,
    .containerDescripcion h1.activo {
        animation: myAnim 1s ease 0s 1 normal forwards;
        opacity: 1;

    }

    @keyframes myAnim {
        0% {
            transform: translateX(600px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    .containerDescripcion h1 {
        margin: 0;
        width: 90%;
        font-size: 22px;
    }

    .containerDescripcion p {
        margin: 0;
    }

    .Borde {
        width: 95%;
        height: 90vh;
        position: absolute;
        border: 2px solid white;
        border-radius: 10px;


    }

    .Borde2 {
        align-items: center;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        display: flex;
        height: 90vh;
        justify-content: center;
        top: 0;
        width: 100%;

    }

    .tituloTalleristas h1 {
        color: white;
        font-size: 40px !important;
    }

    .tituloTalleristas {
        position: absolute;
        top: -20px !important;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 90;


    }
}

.tituloTalleristas {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;


}

.tituloTalleristas h1 {
    color: white;
    font-size: 50px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .sliderContainer {
        height: 700px;
        width: 100%;
        display: flex;
        flex: none;
        padding-top: 100px;
        flex-direction: column;
    }

    .slider {
        position: relative;
        z-index: 10;
        ;
        margin-top: 140px;

    }


    .containerFoto {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 40%;
    }

    .containerFoto>div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .containerDescripcion {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 300px;
    }

    .containerFoto img {
        height: 100%;
        /*     opacity: 0;
     */
        transition: 0.7s ease-in-out all;
        opacity: 0;

    }

    .containerFoto img.active {
        animation: bounce2 1s ease;
        opacity: 1;
        transition: .7s;
    }

    @keyframes bounce2 {

        0% {
            transform: translateX(-400px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    .containerDescripcion button {
        background-color: transparent;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border: 1px solid white;
        color: white;
        margin-top: 50px;
        padding: 0 !important;
    }

    .containerDescripcion button:hover {
        background: rgb(105, 28, 140);
        background: radial-gradient(circle, rgba(105, 28, 140, 0.7933298319327731) 0%, rgba(148, 233, 233, 0) 100%);

    }

    .containerDescripcion {
        color: white;
        padding: 20px;
        text-align: justify;
    }

    .slider {
        display: flex;
        max-width: 100%;
        overflow: hidden;
    }

    .containerDescripcion p,
    .containerDescripcion h1 {
        opacity: 0;
    }

    .containerDescripcion p {
        width: 90%;
        font-size: 12px;
    }

    .containerDescripcion p.activo,
    .containerDescripcion h1.activo {
        animation: myAnim 1s ease 0s 1 normal forwards;
        opacity: 1;

    }

    @keyframes myAnim {
        0% {
            transform: translateX(600px);
        }

        100% {
            transform: translateX(0px);
        }
    }

    .containerDescripcion h1 {
        margin: 0;
        width: 90%;
    }

    .containerDescripcion p {
        margin: 0;
    }

    .Borde {
        width: 95%;
        height: 700px;
        position: absolute;
        border: 2px solid white;
        border-radius: 10px;


    }

    .Borde2 {
        width: 100%;
        height: 700px;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);

    }
}

@media only screen and (min-height: 660px) and (max-height: 900px) and (min-width: 600px) and (max-width: 900px) {
    .sliderContainer {
        display: flex;
        flex: none;
        flex-direction: column;
        height: 600px !important;
        padding-top: 80px !important;
        width: 100%;
    }

    .Borde {
        border: 2px solid #fff;
        border-radius: 10px;
        height: 640px;
        padding-bottom: -2px !important;
        width: 95%;
        margin-bottom: 0px;
    }

    .Borde2 {
        width: 100%;
        height: 700px;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);

    }

    .containerDescripcion p {
        height: 250px;
    }

    .containerFoto {

        margin-top: 30px;

    }

    .sistemaContainer {

        margin-top: 40px !important;

    }
}