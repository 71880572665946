@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

.fondo {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -2;
  max-width: 2000px;
  scroll-behavior: smooth;
}

.fondo video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

body {
  font-family: 'Poppins', sans-serif;
  max-width: 2000px;
  margin: auto;
  scroll-behavior: smooth;
}

* {
  scroll-behavior: smooth;
}