.videosContainer {
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    z-index: 10;
    backdrop-filter: blur(10px);
}

.videoFondo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}




.videosContenido {
    width: 100%;
}

.videosItems {
    display: flex;
    gap: 50px;
    margin-left: 37%;
    height: 100%;
    margin-bottom: 50px;
    align-items: flex-end;

}

.videosItems img {
    height: 200px;
    width: 400px;
    margin-bottom: 60px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.514);
}

.videosBotones {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 0px;
    position: absolute;
    bottom: 0;
}

.videosBotones button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: transparent;
    color: white;
    border: 1px solid white;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
}

.videosBotones button:hover {
    background: rgba(105, 28, 140, 0.400);
}

.videosBotones .play svg {
    fill: white;
}



.videoTitulo {
    text-align: center;
    backdrop-filter: blur(10px);
    color: white;
    font-size: 30px;

}

.videoTitulo h1 {
    margin: 0;
}

@media only screen and (max-width: 600px) {

    .videosContainer {
        height: 400px;
        position: relative;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        z-index: 10;
        backdrop-filter: blur(10px);
    }

    .videoFondo {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }




    .videosContenido {
        width: 100%;
    }

    .videosItems {
        display: flex;
        gap: 50px;
        margin-left: 5%;
        margin-bottom: 50px;
    }

    .videosItems img {
        height: 200px;
        width: 400px;
    }

    .videosBotones {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 50px;
    }

    .videosBotones button {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background: transparent;
        color: white;
        border: 1px solid white;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
    }

    .videosBotones button:hover {
        background: rgba(105, 28, 140, 0.400);
    }

    .videosBotones .play svg {
        fill: white;
    }



    .videoTitulo {
        text-align: center;
        backdrop-filter: blur(10px);
        color: white;
        font-size: 30px;

    }

    .videoTitulo h1 {
        margin: 0;
    }

    .site-as-giant-card {
        background-color: transparent !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {

    .videoTitulo {
        text-align: center;
        backdrop-filter: blur(10px);
        color: white;
        font-size: 30px;
        margin-top: 20px;

    }
}