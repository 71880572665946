.experienciaContainer {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    position: relative;
}

.experienciaItem {
    width: 90%;
    height: 90%;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 1px 1px 3px black;
    display: flex;
}

.experienciaTitulo {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    color: white;
}

.experienciaTitulo h1 {
    font-size: 40px;
    text-shadow: 1px 1px 3px black;
    text-align: center;
    line-height: 40px;
}

.experienciaTitulo div {
    display: flex;
    gap: 30px;
}


.experienciaTitulo button {
    background-color: transparent;
    color: white;
    font-weight: bold;
    border: 1px solid white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 30px;
    cursor: pointer;
}

.experienciaTitulo button:hover {
    background: rgba(105, 28, 140, 0.400);
}

.experienciaItems {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.experienciaItems img {
    height: 400px;
    width: 600px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px black;
    object-fit: cover;
}

.experienciaItems img {
    opacity: 1;

}




.experienciaItemIndicador {
    position: absolute;
    top: 30px;
    right: 100px;
    color: white;
    text-shadow: 1px 1px black;
}

@media only screen and (max-width: 600px) {
    .experienciaContainer {
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);

    }

    .experienciaItem {
        width: 90%;
        height: 90%;
        border-radius: 10px;
        border: 1px solid white;
        box-shadow: 1px 1px 3px black;
        flex-direction: column;
    }

    .experienciaTitulo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        color: white;
    }

    .experienciaTitulo h1 {
        font-size: 40px;
        text-shadow: 1px 1px 3px black;
        text-align: center;
    }

    .experienciaTitulo div {
        display: flex;
        gap: 30px;
    }


    .experienciaTitulo button {
        background-color: transparent;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        border: 1px solid white;
        color: white;
        margin-top: 50px;
        padding: 0 !important;
    }

    .experienciaItems {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

    }

    .experienciaItems img {
        height: 200px;
        width: auto;
        border-radius: 10px;
        box-shadow: 1px 1px 5px black;

    }

    .experienciaItems img {
        opacity: 1;

    }


    .experienciaItemIndicador {
        position: absolute;
        top: 30px;
        right: 30px;
        color: white;
        text-shadow: 1px 1px black;
    }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .experienciaContainer {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);

    }

    .experienciaItem {
        width: 90%;
        height: 90%;
        border-radius: 10px;
        border: 1px solid white;
        box-shadow: 1px 1px 3px black;
        flex-direction: column;
        padding: 12px;

    }

    .experienciaTitulo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        color: white;
        padding: 10px;

    }

    .experienciaTitulo h1 {
        font-size: 40px;
        text-shadow: 1px 1px 3px black;
        text-align: center;
    }

    .experienciaTitulo div {
        display: flex;
        gap: 30px;
    }


    .experienciaTitulo button {
        background-color: transparent;
        color: white;
        font-weight: bold;
        border: 1px solid white;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        font-size: 30px;
        cursor: pointer;
    }

    .experienciaItems {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

    }

    .experienciaItems img {
        height: 400px;
        width: auto;
        border-radius: 10px;
        box-shadow: 1px 1px 5px black;


    }

    .experienciaItems img {
        opacity: 1;
    }

    .experienciaItemIndicador {
        position: absolute;
        top: 30px;
        right: 30px;
        color: white;
        text-shadow: 1px 1px black;
    }
}